import ArchivedItem from "../ArchivedItem";
import { saveAs } from "file-saver";

export const ArchivedItemsList = (props) => {

    const {zipFiles} = props;

    return (
        <>
            {
                !!zipFiles.length
                ?
                zipFiles.map(zipFile => {

                    const handleZipDownload = () => {
                        saveAs(zipFile.content, zipFile.fileName);
                    }

                    return (
                        <ArchivedItem
                            onZipDownload={handleZipDownload}
                            name={zipFile.fileName}
                            key={zipFile.fileName}
                        />
                    )
                })
                :
                null
            }
        </>
    )
}
