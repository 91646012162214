import React, {memo, useEffect, useState} from "react";
import styles from "./SelectLanguage.module.scss";
import {LANGUAGES} from "../../../database";

export const SelectLanguage = memo((props) => {

    const {
        selectedLanguage,
        setSelectedLanguage
    } = props;

    const [searchTerm, setSearchTerm] = useState("");
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleLanguageSelect = (language) => {
        setSelectedLanguage(language);
        setIsDropdownOpen(false);
        setSearchTerm(language);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const filteredLanguages = LANGUAGES.filter((language) =>
        language.toLowerCase().includes(searchTerm.toLowerCase())
    );

    useEffect(() => {
        setSearchTerm(selectedLanguage)
    }, [selectedLanguage]);

    return (
        <div className={styles.SelectLanguage}>
            <div className={styles.SelectLanguage__textFieldContainer}>
                <input
                    type="text"
                    placeholder="Поиск языков..."
                    className={"text-field"}
                    value={searchTerm}
                    onChange={handleInputChange}
                    onClick={toggleDropdown}
                    readOnly={selectedLanguage && !isDropdownOpen}
                />
            </div>
            {isDropdownOpen && (
                <ul className={styles.SelectLanguage__dropdown}>
                    {filteredLanguages.length > 0 ? (
                        filteredLanguages.map((language) => (
                            <li
                                key={language}
                                className={styles.SelectLanguage__dropdownItem}
                                onClick={() => handleLanguageSelect(language)}
                            >
                                {language}
                            </li>
                        ))
                    ) : (
                        <li className={styles.SelectLanguage__dropdownItem}>
                            Нету результатов
                        </li>
                    )}
                </ul>
            )}
        </div>
    );
});

SelectLanguage.displayName = "SelectLanguage";
