import {memo, useCallback, useMemo, useState} from "react";
import {ONLY_NUMBERS_REGEXP} from "../../variables";
import SelectLanguage from "../common/SelectLanguage";

const initialFormState = {
    theme: "",
    count: "",
    language: ""
};

export const DataSearchBar = memo((props) => {

    const {
        onSubmit
    } = props;

    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [form, setForm] = useState(initialFormState);

    const isSubmitDisabled = useMemo(() => {
        return !form.theme || !form.count || !selectedLanguage;
    },[form, selectedLanguage]);

    const handleSubmit = () => {
        form.language = selectedLanguage;
        form.count = +form.count;
        onSubmit(form);
        setForm(initialFormState);
        setSelectedLanguage("");
    }

    const handleChange = useCallback((event) => {

        switch (event.target.name) {
            case "count":
                if (!ONLY_NUMBERS_REGEXP.test(event.target.value) && event.target.value !== "") {
                    return;
                }
        }

        setForm(prevState => {
            return {
                ...prevState,
                [event.target.name]: event.target.value
            }
        })
    },[]);

    return (
        <div className={"text-center w-full flex flex-col gap-5"}>
            <h1 className={"text-3xl font-bold"}>
                С чем я могу помочь?
            </h1>
            <div className={"w-full flex items-start gap-3"}>
                <div className={"w-full flex flex-col items-start gap-3"}>
                    <div className={"w-full flex items-start flex-col gap-1"}>
                        <p>Тема</p>
                        <input
                            onChange={handleChange}
                            value={form.theme}
                            name={"theme"}
                            placeholder={"Тема"}
                            className={"text-field w-full"}
                        />
                    </div>
                    <div className={"w-full grid gap-3 grid-cols-2"}>
                        <div className={"w-full flex items-start flex-col gap-1"}>
                            <p>Количество проектов</p>
                            <input
                                onChange={handleChange}
                                value={form.count}
                                name={"count"}
                                placeholder={"Количество проектов"}
                                className={"text-field w-full"}
                            />
                        </div>
                        <div className={"w-full flex items-start flex-col gap-1"}>
                            <p>Язык</p>
                            <SelectLanguage
                                selectedLanguage={selectedLanguage}
                                setSelectedLanguage={setSelectedLanguage}
                            />
                        </div>
                    </div>
                    <button
                        disabled={isSubmitDisabled}
                        onClick={handleSubmit}
                        className={"button w-full"}
                    >
                        Отправить
                    </button>
                </div>
            </div>
        </div>
    )
});

DataSearchBar.displayName = "DataSearchBar";
