import {ZipIcon} from "../../assets/icons/ZipIcon";

export const ArchivedItem = (props) => {

    let {
        name,
        onZipDownload
    } = props;

    return (
        <div className={"w-full border-2 rounded-xl p-3 flex items-center justify-between"}>
            <div className={"flex items-center gap-3"}>
                <ZipIcon
                    width={32}
                    height={32}
                />
                <p>{ name }</p>
            </div>

            <button
                onClick={onZipDownload}
                className={"button"}
            >
                Скачать
            </button>
        </div>
    )
}
