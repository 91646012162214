import Spinner from "../../../ui/Spinner";

export const LoadingBar = (props) => {

    const {label} = props;

    return (
        <div className={"flex items-center gap-3 border-2 p-3 rounded-xl bg-zinc-50"}>
            <Spinner />
            <p>{label}</p>
        </div>
    )
}
