export const LANGUAGES = [
    "Албанский", "Армянский", "Белорусский", "Боснийский", "Болгарский",
    "Каталанский", "Хорватский", "Чешский", "Датский", "Голландский", "Английский",
    "Эстонский", "Финский", "Французский", "Грузинский", "Немецкий",
    "Греческий", "Венгерский", "Исландский", "Испанский", "Ирландский", "Итальянский", "Латышский",
    "Литовский", "Норвежский",
    "Польский", "Португальский", "Румынский", "Русский", "Сербский", "Словацкий",
    "Словенский", "Шведский", "Турецкий", "Украинский"
];

export const ROBOTS_CONTENT = `# hestiacp autogenerated robots.txt 
User-agent: * 
Crawl-delay: 10
`;
