export class Helpers {
    static retrieveImagesCount = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const images = doc.querySelectorAll('img');
        return Array.from(images).filter(img => img.src.includes('IMAGE_PLACEHOLDER')).length;
    }

    static sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
}
